
export interface ITimer {
  id: number
  time: number
  comment: string
}

export class TimerData implements ITimer {
  id: number
  comment = ""
  get time(): number {
    const currentPeriod = this._started ? Date.now() - this._started : 0
    return (this._accumulatedTime + currentPeriod) / 1000
  }

  get isRunning() {
    return !!this._started
  }

  _accumulatedTime = 0
  _started?: number

  start() {
    if(this._started) return

    this._started = Date.now()
  }

  stop() {
    if(!this._started) return

    this._accumulatedTime += Date.now() - this._started
    this._started = undefined
  }

  constructor(id: number = 1) {
    this.id = id
  }

  loadSerializedData(data: string) {
    const obj = JSON.parse(data)
    this.id = obj.id
    this.comment = obj.comment
    this._started = obj.started
    this._accumulatedTime = obj.accumulatedTime
  }

  serialize() {
    return JSON.stringify({
      id: this.id,
      comment: this.comment,
      started: this._started,
      accumulatedTime: this._accumulatedTime,
    })
  }
}