import React, { HTMLAttributes } from 'react'
import { StyledCircleButton } from './CircleButton.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface ICircleButtonProps extends HTMLAttributes<HTMLButtonElement> {
    icon?: IconProp
}
export function CircleButton(props: ICircleButtonProps) {
    return <StyledCircleButton {...props}>
        <FontAwesomeIcon icon={props.icon}/>
    </StyledCircleButton>
}