import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ITimer } from '../../models/timer';
import { TimerIcon, StyledInput, StyledTime, StyledTimer, DeleteButton } from './Timer.styles';
import { formatTime } from '../../utils';

interface ITimerProps {
  timer: ITimer,
  isActive?: boolean,
  onChangeComment?: (timer: ITimer, text: string) => void,
  onDeleteTimer?: (timer: ITimer) => void,

}


export function Timer(props: ITimerProps) {
  const { isActive, timer, onChangeComment, onDeleteTimer } = props

  const handleChangeComment = useCallback((e) => {
    if (onChangeComment) onChangeComment(timer, e.target.value)
  }, [timer, onChangeComment])

  const handleDeleteClick = useCallback(() => {
      if(onDeleteTimer) onDeleteTimer(timer)
  }, [timer, onDeleteTimer])

  return (
    <StyledTimer>
        <StyledTime>{formatTime(timer.time)}</StyledTime>
        <StyledInput placeholder="comment" value={timer.comment} onChange={handleChangeComment} />
        { onDeleteTimer
            ? <DeleteButton onClick={handleDeleteClick}><FontAwesomeIcon icon="trash" /></DeleteButton>
            : <TimerIcon>{isActive ? <FontAwesomeIcon icon="sync" spin /> : <FontAwesomeIcon icon="stopwatch"/>}</TimerIcon>
        }
    </StyledTimer>
  )
}