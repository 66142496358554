import { useCallback, useEffect, useRef, useState } from 'react'

export function useInterval(callback, delay=1000) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const runner = () => callbackRef.current && callbackRef.current()

    const id = delay ? setInterval(runner, delay) : undefined
    return () => id !== undefined && clearInterval(id)

  }, [delay])
}

export function useAudio(src: string) {
  const [audio, setAudio] = useState<HTMLAudioElement>(null)

  useEffect(() => {
    setAudio(new Audio(src))
  }, [src])

  return audio //audioRef.current
}

export function useForceUpdate() {
  const [, _update] = useState(0)
  return useCallback(() => {
    _update(x => x + 1)
  }, [])
}