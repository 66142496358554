import React, { useCallback, useEffect, useState } from "react"
import styled from 'styled-components/macro'
import { ToastContainer, toast } from 'react-toastify';
import debounce from 'lodash/debounce'
import 'normalize.css'
import 'react-toastify/dist/ReactToastify.min.css'

import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from '@fortawesome/free-solid-svg-icons'

import { GlobalStyle } from '../styles/globalStyle'

import { Timer } from "../components/Timer"
import { useAudio, useForceUpdate, useInterval } from "../hooks"
import { ITimer, TimerData } from "../models/timer"
import { formatTime } from "../utils"

import { CircleButton } from "../components/CircleButton"

library.add(fas)

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  width: 100%;
  max-width: 720px;
  padding: 1rem;
  border-radius: 0.5rem;
  //background: #e0e0e0;
`

const Card = styled.div`
  padding: 0.5rem;
`

const ButtonBar = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`

const STORAGE_KEY = 'timetable'
const storeTimers = debounce(function storeTimers(timers: TimerData[], isActive: boolean) {
  const timerData = timers.map(t => t.serialize())
  localStorage.setItem(STORAGE_KEY, JSON.stringify({
    timers: timerData,
    isActive: isActive,
  }))
}, 100)

export default function Home() {
  const [isActive, setIsActive] = useState(false)
  const [timers, setTimers] = useState<TimerData[]>([])
  const signal = useAudio('/3signal.mp3')
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    // Create initial timer
    const storedData = localStorage.getItem(STORAGE_KEY)
    if (storedData === null) {
      setTimers([new TimerData(1)])
      return
    }

    const data = JSON.parse(storedData)
    setTimers(
      data.timers.map(serializedTimer => {
        const timer = new TimerData()
        timer.loadSerializedData(serializedTimer)
        return timer
      })
    )

    setIsActive(data.isActive)
  }, [])

  useInterval(() => {
    forceUpdate()
    storeTimers(timers, isActive)
  }, isActive ? 250 : 0)


  const toggleTimer = useCallback(() => {
    if (!isActive) {
      timers[0].start()
    } else {
      timers[0].stop()
    }

    setIsActive(!isActive)
    storeTimers(timers, !isActive)
  }, [isActive, timers])


  const handleClickReset = useCallback(() => {
    const newTimer = new TimerData(timers[0].id + 1)
    timers[0].stop()
    setIsActive(false)
    setTimers([newTimer, ...timers])
    storeTimers(timers, false)
  }, [timers])


  const playSignal = useCallback(async () => {
    signal.play()
  }, [signal])


  const handleChangeCurrentComment = useCallback((timer: ITimer, comment: string) => {
    const index = timers.findIndex(t => t.id === timer.id)
    timers[index].comment = comment
    forceUpdate()
    storeTimers(timers, isActive)
  }, [timers, isActive])


  const handleDeleteTimer = useCallback((timer: ITimer) => {
    const updatedTimers = timers.filter(t => t.id !== timer.id)
    setTimers(updatedTimers)
    storeTimers(updatedTimers, isActive)

  }, [timers, isActive])


  const copyTable = useCallback(() => {
    const text = (timers.map(t => `${formatTime(t.time)} - ${t.comment}`)).reverse().join("\n")
    navigator.clipboard.writeText(text).then(() => {
      toast("Time table was copied to clipboard", { type: "info", autoClose: 4000 })
    })
  }, [timers])

  return (
    <>
      <GlobalStyle />
      <Container>
        <Content>
          <div>
            {
              timers.slice(1).reverse().map(
                (t) => <div key={t.id}><Timer timer={t} onChangeComment={handleChangeCurrentComment} onDeleteTimer={handleDeleteTimer}/></div>)
            }
          </div>
          <div>
            {!!timers[0] && <Timer isActive={isActive} timer={timers[0]} onChangeComment={handleChangeCurrentComment} />}
          </div>
          <ButtonBar>
            <CircleButton icon={isActive ? "pause" : "play"} onClick={toggleTimer} />
            <CircleButton icon="plus" onClick={handleClickReset}/>
            <CircleButton icon="bell" onClick={playSignal} />
            <CircleButton icon="copy" onClick={copyTable} />
          </ButtonBar>
        </Content>
      </Container>
      <ToastContainer position="bottom-center" />
    </>
  )
}
