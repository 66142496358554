import styled from 'styled-components/macro'

export const StyledTimer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
`


export const TimerIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
`

export const DeleteButton = styled(TimerIcon)`
    cursor: pointer;

    color: red;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }
`

export const StyledTime = styled.div`
    padding: 0.5rem 1rem;
    padding-left: 0;
`

export const StyledInput = styled.input`
    flex: 1;
    height: 1.5rem;
`